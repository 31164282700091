<template>
	<div>
		<el-row :gutter="10">
			<el-col :xs="24" :sm="24">
				<el-card>
					<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
						<el-tab-pane name="managedata">
							<span slot="label">
								<i class="el-icon-s-order"></i>
								年度销售目标管理
							</span>
							<div>
								<el-input v-model="search" placeholder="搜索" @input="fetchData"
									style="margin-bottom: 20px; margin-right:20px; width: 300px;"
									size="mini"></el-input>

								<el-button type="primary" @click="openDialog()" size="mini">添加销售目标数据</el-button>
								<el-button type="danger" @click="deleteSelected" size="mini"
									:disabled="!multipleSelection.length">批量删除</el-button>

								<el-table ref="multipleTable" :data="salesTargets" stripe style="width: 100%;"
									@sort-change="handleSortChange" @selection-change="handleSelectionChange"
									v-loading="loading">
									<el-table-column type="selection" width="55"></el-table-column>
									<el-table-column prop="year" label="年份" sortable>
										<template slot-scope="scope">
											{{ scope.row.year.split('-')[0]}}
										</template>

									</el-table-column>
									<el-table-column prop="province" label="省份" sortable></el-table-column>
									<el-table-column prop="target_sales_volume" label="目标销量(桶)"
										sortable></el-table-column>
									<el-table-column prop="target_sales_amount" label="目标销售额(元)"
										sortable></el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button-group>
												<el-button size="mini" icon="el-icon-edit"
													@click="openDialog(scope.row)">改</el-button>
												<el-button type="danger" size="mini" icon="el-icon-delete"
													@click="deletesalesTargets(scope.row)">删</el-button>
											</el-button-group>
										</template>
									</el-table-column>
								</el-table>

								<el-pagination v-if="total > 0" @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="currentPage"
									:page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="total"
									class="paginator hidden-sm-and-down">
								</el-pagination>

								<el-dialog :title="dialogTitle" :visible.sync="dialogVisible"
									:close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
									<el-form :model="formData" :rules="rules" ref="form">
										<el-form-item label="年份" prop="year">
											<el-date-picker v-model="formData.year" type="year"
												value-format="yyyy-MM-dd" placeholder="选择年份" ></el-date-picker>
										</el-form-item>
										<el-form-item label="省份/地区" prop="province">
											<el-select v-model="formData.province" filterable placeholder="请选择省份" >
												<el-option v-for="item in provinceOption" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="目标销量(桶)" prop="target_sales_volume">
											<el-input-number v-model="formData.target_sales_volume"></el-input-number>
										</el-form-item>
										<el-form-item label="目标销售额(元)" prop="target_sale_amount">
											<el-input-number v-model="formData.target_sales_amount"></el-input-number>
										</el-form-item>
									</el-form>
									<div slot="footer" class="dialog-footer">
										<el-button @click="dialogVisible = false">取消</el-button>
										<el-button type="primary" @click="saveData">保存</el-button>
									</div>
								</el-dialog>

								<!-- 这里可以添加经销商数据管理的内容 -->
							</div>
						</el-tab-pane>
						<el-tab-pane name="uploadfile">
							<span slot="label">
								<i class="el-icon-s-platform"></i>
								年度销售目标数据导入
							</span>
							<div>
								<el-row :gutter="10">
									<el-col :sm="8" :xs="24">
										<el-form :disabled="isUploading">
											<el-form-item label="选择文件" :required="true">
												<el-upload action="none" class="upload-demo" ref="upload"
													:auto-upload="false" :on-change="handleFileChange"
													:on-remove="handleFileRemove" :multiple="false" accept=".xlsx"
													:file-list="fileList">
													<el-button size="small" type="primary">选择文件</el-button>
													<div slot="tip" class="el-upload__tip">只能上传 .xlsx文件</div>
												</el-upload>
											</el-form-item>
										</el-form>
										<el-button :disabled="isUploading" @click="submitUpload">导入</el-button>

									</el-col>
									<el-col :sm="16" :xs="24">
										<el-table :data="importResultTableData" border style="width: 100%"
											max-height="500">
											<el-table-column label="验证结果">
												<el-table-column prop="row" label="行数" width="180">
												</el-table-column>
												<el-table-column prop="message" label="问题">
												</el-table-column>
											</el-table-column>
										</el-table>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				activeName: 'managedata', // 设置默认显示的 tab
				selectedFile: null, // 用于存储用户选择的文件
				isUploading: false, // 用于在上传时禁用按钮
				fileList: [],
				importResultTableData: [{
					row: '',
					message: ''
				}],
				salesTargets: [],
				provinceOption: [{
						"item": "河南",
						"value": "河南"
					},
					{
						"item": "辽宁",
						"value": "辽宁"
					},
					{
						"item": "内蒙古",
						"value": "内蒙古"
					},
					{
						"item": "山西",
						"value": "山西"
					},
					{
						"item": "新疆",
						"value": "新疆"
					},
					{
						"item": "山东",
						"value": "山东"
					},
					{
						"item": "湖北",
						"value": "湖北"
					},
					{
						"item": "广西",
						"value": "广西"
					},
					{
						"item": "贵州",
						"value": "贵州"
					},
					{
						"item": "陕/甘/宁/青",
						"value": "陕/甘/宁/青"
					},
					{
						"item": "四川/重庆",
						"value": "四川/重庆"
					},
					{
						"item": "湖南",
						"value": "湖南"
					},
					{
						"item": "北京",
						"value": "北京"
					},
					{
						"item": "天津/河北",
						"value": "天津/河北"
					},
					{
						"item": "黑龙江",
						"value": "黑龙江"
					},
					{
						"item": "江西",
						"value": "江西"
					},
					{
						"item": "安徽",
						"value": "安徽"
					},
					{
						"item": "福建",
						"value": "福建"
					},
					{
						"item": "广东",
						"value": "广东"
					},
					{
						"item": "海南",
						"value": "海南"
					},
					{
						"item": "江苏",
						"value": "江苏"
					},
					{
						"item": "云南",
						"value": "云南"
					},
					{
						"item": "上海",
						"value": "上海"
					},
					{
						"item": "浙江",
						"value": "浙江"
					},
					{
						"item": "零售",
						"value": "零售"
					}
				],
				search: '',
				total: 100,
				currentPage: 1,
				pageSize: 10,
				sortField: '',
				sortOrder: '',
				loading: false,
				multipleSelection: [],
				dialogVisible: false,
				dialogTitle: '添加销售目标',
				formData: {
					id: null,
					year: '',
					province: '',
					target_sales_volume: 0,
					target_sales_amount: 0,
				},
				rules: {
					year: [{
						required: true,
						message: '请选择日期',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '请输入省份',
						trigger: 'blur'
					}],
					target_sales_volume: [{
						required: true,
						message: '请输入目标销量',
						trigger: 'blur'
					}],
					target_sales_amount: [{
						required: true,
						message: '请输入目标销售额',
						trigger: 'blur'
					}],
				}
			};
		},
		methods: {
			handleClick(tab) {
				// console.log('切换到 tab:', tab.name);
			},

			handleFileChange(file, fileList) {
				this.selectedFile = file.raw; // 获取原始文件对象
				this.fileList = fileList.slice(-1);
			},
			handleFileRemove(file, fileList) {
				this.selectedFile = null; // 获取原始文件对象
			},
			submitUpload() {
				let that = this;
				if (!this.selectedFile) {
					this.$message.warning('请先选择要上传的文件');
					return;
				}
				this.isUploading = true;
				const formData = new FormData();
				formData.append('file', this.selectedFile);
				that.$axios({
						url: '/apis/import_yearly_sales_target/',
						method: 'post',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						that.selectedFile = null;
						that.fileList = [];
						that.$refs.upload.clearFiles()
						if (res.data.code == '401') {
							that.importResultTableData = res.data.errors;
							that.$message({
								message: res.data.message,
								type: 'warning',
								onClose: function() {
									that.isUploading = false;
								}
							});
						} else if (res.data.code == '200') {
							// console.log(res.data.message)
							that.importResultTableData = res.data.message.errors;
							that.fetchData();
							that.$message({
								message: '成功导入' + res.data.message.success + '条数据',
								type: 'success',
								onClose: function() {
									that.isUploading = false;
								}
							});
						}
						that.fetchData();
					})
					.catch(function(error) {
						that.selectedFile = null;
						that.fileList = [];
						that.$refs.upload.clearFiles()
						that.$message({
							message: error,
							type: 'warning',
							onClose: function() {
								that.isUploading = false;
							}
						});
					});
					that.fetchData();
			},

			//获取年度销售目标数据
			fetchData() {
				this.loading = true;
				this.$axios.get('/apis/order_data/yearly_sale_target/', {
					params: {
						search: this.search,
						ordering: this.sortOrder ?
							`${this.sortOrder === 'descending' ? '-' : ''}${this.sortField}` : '',
						page: this.currentPage,
						page_size: this.pageSize
					}
				}).then(response => {
					if (response.data.code == '401') {
						that.logout();
					}
					this.salesTargets = response.data.results;
					this.total = response.data.count;
				}).finally(() => {
					this.loading = false;
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.fetchData();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.fetchData();
			},
			handleSortChange({
				prop,
				order
			}) {
				this.sortField = prop;
				this.sortOrder = order;
				this.fetchData();
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			openDialog(row = null) {
				this.dialogVisible = true;
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});
				if (row) {
					this.dialogTitle = '修改销售目标';
					this.formData = {
						...row,
					};
				} else {
					this.dialogTitle = '新增销售目标';
					this.formData = {
						id: null,
						year: '',
						province: '',
						target_sales_volume: 0,
						target_sales_amount: 0,
					};
				}
			},
			//保存记录
			saveData() {
				let that = this;
				this.$refs.form.validate((valid) => {
					if (valid) {
						const method = this.formData.id ? 'put' : 'post';
						const url = this.formData.id ?
							`/apis/order_data/yearly_sale_target/${this.formData.id}/` :
							'/apis/order_data/yearly_sale_target/';
						this.$axios[method](url, this.formData)
							.then(() => {
								this.dialogVisible = false;
								this.fetchData();
							})
							.catch(error => {
								if (error.response && error.response.status === 400) {
									// console.log(error.response.data.non_field_errors)
									that.$message({
										message: error.response.data.non_field_errors.join(','),
										type: 'warning',
									});
								} else {
									that.$message({
										message: '保存失败，服务器错误',
										type: 'warning',
									});
								}
							});
					}
				});
			},
			// 单个删除
			deletesalesTargets(row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$axios.delete(`/apis/order_data/yearly_sale_target/${row.id}/`)
							.then(() => {
								this.fetchData();
							});
					})
					.catch(() => {});
			},
			// 批量删除
			deleteSelected() {
				this.$confirm('此操作将永久批量删除选中的记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						const ids = this.multipleSelection.map(item => item.id);
						this.$axios.post('/apis/order_data/yearly_sale_target/batch-delete/', {
								ids
							})
							.then(() => {
								this.fetchData();
							});
					})
					.catch(() => {});
			},

		},
		mounted() {
			this.fetchData();
		}
	};
</script>

<style scoped>
	.upload-demo .el-upload {
		display: inline-block;
	}
</style>